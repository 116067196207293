<template>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <CardImport/>
      </div>
    </div>
  </template>
  <script>
  import CardImport from "@/components/Cards/CardImport.vue";
  
  export default {
    components: {
        CardImport
    },
  };
  </script>
  