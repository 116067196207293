<script>
import messages from '../en/messages.vue';
import labels from '../en/labels.vue';
import text from '../en/text.vue';
import app from '../en/app.vue';


export default 
  {
    messages: messages,
    labels: labels,
    text: text,
    app: app
  }
</script>
