<script>
export default {
    about_us: {
        version: `Budget Control de Mlabfactory versión `,
        personal_finance: `<b>Gestor de finanzas personales</b><p>Budget Control de Mlabfactory es una herramienta de gestión de finanzas personales que te ayuda a seguir tus gastos, para que puedas mantener el control y alcanzar tus metas.</p>`,
        information: `<b>Información de Budget Control</b><p>Mlabfactory es un desarrollador profesional con sede en Italia, Milán, que opera y desarrolla aplicaciones desde 2008. Descubre más sobre el equipo</p><a href="http://mlabfactory.it">Mlabfactory.it</a>`,
        visit_our_website: 'Visita nuestro sitio web',
    },
    assistance: {
        write_question: `<p>Escribe aquí tu pregunta</p>`,
        thanks: `Gracias por tu solicitud. Nos pondremos en contacto contigo lo antes posible.`,
    },
    profile: {
        policy: `<p>Registrándote o conectándote con uno de los servicios mencionados, consientes nuestros <a class="font-bold text-decoration-line" href="https://www.budgetcontrol.cloud/terms/">Términos de Servicio</a> y reconoces nuestra <a class="font-bold text-decoration-line" href="https://www.budgetcontrol.cloud/privacy/">Política de Privacidad</a>, que describe cómo manejamos tus datos personales.</p>`
    },
    settings: {
        manage_your_workspace: 'Gestiona tus espacios de trabajo.',
        manage_wallets: 'Gestiona billeteras, cambia iconos, color.',
        manage_entry: 'Gestiona tus categorías de entrada.',
        customize_entry: 'Personaliza las etiquetas de entrada.',
        configure_model: 'Configura los modelos de entrada.',
        set_currency: 'Establece tu moneda preferida.',
        edit_profile: 'Edita tu perfil de usuario.',
        learn: 'Aprende más sobre nuestro equipo.',
        write_us: 'Escríbenos para asistencia.',
    },
    install_app: 'Hey! ¿Quieres instalar la aplicación?',
}
</script>
