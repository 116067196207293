<script>
import messages from '../it/messages.vue';
import labels from '../it/labels.vue';
import text from '../it/text.vue';
import app from '../it/app.vue';

export default 
  {
    messages: messages,
    labels: labels,
    text: text,
    app: app
  }
</script>
