<script>
export default {
    sign_in_with: 'Accedi con',
    or_sign_in_with_credentials: 'Oppure accedi con le credenziali',
    sign_up_with: 'Registrati con',
    generic_error: 'Si è verificato un errore. Per favore riprova più tardi.',
    i_agree_with_the: 'Accetto i ',
    we_have_sent_you_an_email: "Ti abbiamo inviato un'email :-)",
    email: 'Email',
    password: 'Password',
    lost_your_password: 'Hai perso la password?',
    recovery_here: 'recuperala qui',
    sign_in: 'Accedi',
    forgot_password: 'Password dimenticata?',
    create_new_account: 'Crea nuovo account',
    terms_of_service: 'Termini di Servizio',
    link_privacy: 'Politica sulla Privacy',
    term_conditions_text: 'Registrandoti o accedendo con uno dei servizi sopra menzionati, acconsenti ai nostri {link_terms} e riconosci la nostra {link_privacy}, che descrive come gestiamo i tuoi dati personali.',
    about_us: 'Chi Siamo',
    blog: 'Blog',
    mit_license: 'Licenza MIT',
    docs: 'Documenti',
    loading: 'Caricamento',
    dashboard: 'Dashboard',
    add_new_entry: 'Aggiungi nuova voce',
    add_new_model: 'Aggiungi nuovo modello',
    my_entries: 'Le mie voci',
    search_entries: 'Cerca voci',
    payee_list: 'Elenco debiti',
    planned_entries: 'Voci pianificate',
    budgets: 'Budget',
    settings: 'Impostazioni',
    logout: 'Esci',
    expenses: 'Spese',
    incoming: 'Entrate',
    transfer: 'Trasferimento',
    debit: 'Debiti',
    choose_one_of_currently_tags: 'Scegli uno dei tag attuali',
    choose_a_wallet_to_transfer_to: 'Scegli un portafoglio a cui trasferire',
    choose_wallet_account: 'Scegli account del portafoglio',
    choose_wallet_to_transfer: 'Scegli un portafoglio a cui trasferire',
    payment_confirg: 'Conferma pagamento',
    choose_an_option: "Scegli un'opzione",
    choose_a_model: 'Scegli un modello',
    out_of_wallet: 'Fuori dal portafoglio',
    insert_new_tag_name: 'Inserisci nuovo nome TAG',
    add_here_your_note: 'Aggiungi qui la tua nota',
    insert_new_entry: 'Inserisci nuova voce',
    show_planned_entries: 'Mostra voci pianificate',
    show_more: 'Mostra di più',
    restore: 'Ripristina',
    reasume_of_all_entries: 'Riepilogo di tutte le voci',
    list_of_all_payees: 'Elenco di tutti i beneficiari',
    forget_debit: 'Dimentica addebito',
    date_start: 'Data inizio',
    debit_name: 'Nome addebito',
    date_end: 'Data fine',
    choose_frequency: 'Scegli una frequenza',
    choose_method: 'Scegli un metodo',
    insert_new_planned_entry: 'Inserisci nuova voce pianificata',
    create_new_budget: 'Crea nuovo budget',
    budget_name: 'Nome budget',
    period: 'Periodo',
    budget: 'Budget',
    account: 'Account',
    category: 'Categoria',
    label: 'Etichetta',
    labels: 'Etichette',
    entry_type: 'Tipo di voce',
    enable_email_notification: 'Abilita notifica email',
    save_budget: 'Salva budget',
    add_new_wallet: 'Aggiungi nuovo portafoglio',
    show_archived: 'Mostra archiviati',
    wallet_settings: 'Impostazioni portafoglio',
    category_settings: 'Impostazioni categoria',
    add_new_category: 'Aggiungi nuova categoria',
    category_name: 'Nome categoria',
    save_changes: 'Salva modifiche',
    archive_label: 'Archivia etichetta',
    add_model_entry: 'Aggiungi nuova voce modello',
    model_settings: 'Impostazioni modello',
    set_your_default_currency: 'Imposta la tua valuta predefinita',
    currency_settings: 'Impostazioni valuta',
    wallets: 'Portafogli',
    models: 'Modelli',
    categories: 'Categorie',
    currency: 'Valuta',
    profile: 'Profilo',
    assistance: 'Assistenza',
    my_entry: 'La mia voce',
    is_planned: 'Pianificati',
    add: 'Aggiungi',
    model: 'Modello',
    wallet: 'Portafoglio',
    payee: 'Beneficiario',
    frequency: 'Frequenza',
    method: 'Metodo',
    amount: 'Importo',
    date: 'Data',
    tag: 'Tag',
    tags: 'Tag',
    note: 'Nota',
    save: 'Salva',
    cancel: 'Annulla',
    close: 'Chiudi',
    confirm: 'Conferma',
    delete: 'Elimina',
    edit: 'Modifica',
    show: 'Mostra',
    hide: 'Nascondi',
    archive: 'Archivia',
    unarchive: 'Disarchivia',
    search: 'Cerca',
    show_details: 'Mostra dettagli',
    bounce_rate: 'Tasso di rimbalzo',
    session_duration: 'Durata sessione',
    amount_before: 'Importo precedente',
    send_a_request: 'Invia una richiesta',
    change_order: 'Cambia ordine',
    add_new_workspace: 'Aggiungi nuovo spazio di lavoro',
    set_up_your_workspaces: 'Configura i tuoi spazi di lavoro',
    choose_parent_category: 'Scegli una categoria principale',
    exclude_from_stats: 'Escludi dalle statistiche',
    label_color: 'Colore etichetta',
    choose_currency: 'Scegli una valuta',
    choose_wallet_type: 'Scegli un tipo di portafoglio',
    payment_deadline: 'Scadenza pagamento',
    credit_card_installment: 'Rata della carta di credito',
    wallet_color: 'Colore portafoglio',
    workspace_settings: 'Impostazioni spazio di lavoro',
    default_currency: 'Valuta predefinita',
    default_payment_type: 'Tipo di pagamento predefinito',
    share_workspace_with: 'Condividi spazio di lavoro con',
    share: 'Condividi',
    remove: 'Rimuovi',
    workspace_shared_with: 'Spazio di lavoro condiviso con',
    choose_a_category: 'Scegli una categoria',
    payment_confirm: 'Conferma pagamento',
    my_planned: 'Pianificati',
    my_health: 'Salute economica',
    workspace: 'Workspace',
    entry_saved: 'Voce salvata correttamente',
    could_not_log_in: 'Non riesco ad accedere',
    send_my_password: 'Invia una nuova password',
    recovery_your_password: 'Recupera la tua password',
    name: 'Nome',
    confirm_password: 'Conferma password',
    privacy_policy: 'Privacy & Policy',
    create_account: 'Crea un nuovo account',
    click_here: 'Clicca qui',
    save_template: 'Salva modello',
    set_new_password: 'Imposta nuova password',
    account_created: 'Account creato con successo, verifica la tua email per confermare ed attivare il tuo account',
    search_by_text: 'Cerca per testo',
    no: 'NO',
    yes: 'SI',
    date_interval: 'Intervallo di date',
    type_of_transaction: 'Tipo di transazione',
    choose_period: 'Scegli un periodo',
    daily: 'Giornaliero',
    weekly: 'Settimanale',
    monthly: 'Mensile',
    yearly: 'Annuale',
    one_shot: 'Una tantum',
    recursive: 'Ricorrente',
    or_nsert_new_tag_name: 'Oppure inserisci un nuovo nome TAG',
    no_budgets_found: 'Nessun budget trovato',
    list_of_entries_of: 'Elenco delle voci di',
    no_payees_found: 'Nessun debito trovato',
    list_of_all_budgets: 'Elenco di tutti i budget',
    list_of_all_planned_entries: 'Elenco di tutte le voci pianificate',
    set_your_default_currecny: 'Imposta la tua valuta predefinita',
    update: 'Aggiorna',
    stats: 'Statistiche',
    payment_types: 'Tipi di pagamento',
    create_new_debit: 'Crea nuovo debito',
    months: {
        jan: 'Gen',
        january: 'Gennaio',
        feb: 'Feb',
        february: 'Febbraio',
        mar: 'Mar',
        march: 'Marzo',
        apr: 'Apr',
        april: 'Aprile',
        may: 'Mag',
        jun: 'Giu',
        june: 'Giugno',
        jul: 'Lug',
        july: 'Luglio',
        aug: 'Ago',
        august: 'Agosto',
        sep: 'Set',
        september: 'Settembre',
        oct: 'Ott',
        october: 'Ottobre',
        nov: 'Nov',
        november: 'Novembre',
        dec: 'Dic',
        december: 'Dicembre',
    },
    closing_account_statement: 'Chiusura estratto conto',
    account_payment: 'Conto di pagamento',
    start_date: 'Data di inizio',
    end_date: 'Data di fine',
    show_entries: 'Mostra voci',
    wallet_name: 'Nome del portafoglio',
    balance_wallet: 'Saldo del portafoglio',
    wallet_type: 'Tipo di portafoglio',
    next_execution_time: 'Prossimo data di esecuzione',
    end_time: 'Data di fine',
    no_data_found: 'Nessun dato trovato',
    average_incoming: 'Entrate medie',
    average_savings: 'Risparmi medi',
    your_planned_expenses: 'Spese pianificate',
    average_expenses: 'Spese medie',
    list_of_voices: 'Elenco delle voci',
    no_voices_found: 'Nessuna voce trovata',
    workspace_whared_with: 'Spazio di lavoro condiviso con',
    user_not_found: 'Utente non trovato',
    save_as_model: 'Salva come modello',
    write_temlate_name: 'Scrivi il nome del modello',
    end_of_month: 'Fine mese',
    ticket_value: 'Valore del voucher',
    spent: 'speso',
    remaining: 'rimanente',
    planned: 'pianificato',
    no_entries_found: 'Nessuna voce trovata',
    credit_card_installement: 'Rata della carta di credito',
    choose_planned_type: 'Scegli un tipo pianificato',
}
</script>