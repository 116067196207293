<script>
import messages from '../es/messages.vue';
import labels from '../es/labels.vue';
import text from '../es/text.vue';
import app from '../es/app.vue';

export default 
  {
    messages: messages,
    labels: labels,
    text: text,
    app: app
  }
</script>
