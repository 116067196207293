<script>
export default {
    wrong_password: `Las credenciales ingresadas no son válidas.`,
    email_not_verified: `Aún no has verificado tu correo electrónico. Si no lo has recibido, haz clic aquí para reenviarlo.`,
    model_saved: `Modelo guardado exitosamente.`,
    entry_Saved: `Entrada guardada exitosamente.`,
    generic_error: `Se produjo un error. Por favor, inténtelo de nuevo más tarde.`,
    validation: {
        choose_Wallet: 'Por favor, elija una cuenta de billetera',
        choose_Category: 'Por favor, elija una categoría',
        insert_amount: 'Por favor, ingrese un valor para el monto',
        choose_Wallet_transfer: 'Por favor, elija una billetera diferente para la transferencia',
        insert_payee: 'Por favor, ingrese un nombre de beneficiario',
    },
    login: {
        not_verified_email: `Aún no has verificado tu correo electrónico. Si no lo has recibido, haz clic aquí para reenviarlo.`,
        not_valid_password: `Las credenciales ingresadas no son válidas.`,
    },
    wallet: {
        are_you_sure: '¿Estás seguro de que quieres archivar esta billetera?',
        archived: 'Billetera archivada',
        restored: 'Billetera restaurada',
        saved: 'Billetera guardada',
        wrong_Wallet: 'Por favor, elija un tipo de billetera correcto',
        wallet_name: 'Por favor, ingrese un nombre para la billetera',
        wallet_currency: 'Por favor, elija una moneda para la billetera',
        invoice_date: 'Por favor, elija una fecha de factura',
        closing_date: 'Por favor, elija una fecha de cierre',
        payment_account: 'Por favor, elija una cuenta de pago',
        installment: 'Por favor, elija un valor de cuota',
        voucher_value: 'Por favor, ingrese un valor de cupón',
    },
    workspace: {
        updated: 'Espacio de trabajo actualizado',
        added: 'Espacio de trabajo agregado',
        user_not_fount: 'Usuario no encontrado'
    },
    search: {
        no_results: 'No se encontraron resultados',
        please_set_date: 'Elija una fecha de inicio y fin',
    },
}
</script>