<script>
import en from './languages/en.vue'
import it from './languages/it.vue';
import es from './languages/es.vue';


export default 
  {
    en: en,
    it: it,
    es: es,
  }
</script>
